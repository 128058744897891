// React Core
import React, { useState } from 'react';
// Material-UI and other Libraries
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import LinkTwo from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
// Adapify
import gtag from '../Utilities/Gtag';

const Header = () => {
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    logo: {
      margin: '30px 60px 20px',
      height: 60,
      [theme.breakpoints.down(1040)]: {
        marginLeft: '6%',
      },
      [theme.breakpoints.down(1150)]: {
        marginTop: '6.5%',
        marginBottom: '3%',
      },
    },
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    dockerAppearance: {
      display: 'none',
      '& .MuiPaper-root': {
        width: '30%',
        [theme.breakpoints.down(1150)]: {
          width: '45%',
        },
      },
      [theme.breakpoints.down(1150)]: {
        display: (open) ? 'flex' : 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      [theme.breakpoints.down(1150)]: {
        maxHeight: 91,
        width: '100%',
      },
    },
    menuIcon: {
      display: 'none',
      [theme.breakpoints.down(1150)]: {
        display: 'flex',
        marginLeft: '2%',
        marginTop: '2%',
        marginBottom: '1%',
      },
      // [theme.breakpoints.down(1150)]: {
      //   display: 'flex',
      //   marginLeft: '2%',
      //   marginTop: '1%',
      //   marginBottom: '1%',
      // },
      [theme.breakpoints.down(1150)]: {
        display: 'flex',
        marginLeft: '2%',
        marginTop: '2%',
        marginBottom: '2%',
      },
      [theme.breakpoints.down(600)]: {
        display: 'flex',
        marginLeft: '4%',
        marginTop: '2.5%',
        marginBottom: '2%',
      },
    },
    menuIconButton: {
      fontWeight: 500,
    },
    menuServicesIcon: {
      paddingTop: '10px',
    },
    toolbarTitle: {
      justifyContent: 'space-between',
      [theme.breakpoints.down(1150)]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      },
    },
    toolBarWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down(1150)]: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
      },
    },
    navLinks: {
      marginRight: 60,
      [theme.breakpoints.down(1150)]: {
        width: '100%',
        margin: 10,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      },
    },
    homeLink: {
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: 18,
      float: 'left',
      color: '#A19DAF',
      justifySelf: 'center',
      textTransform: 'capitalize',
      paddingTop: '6px',
      margin: theme.spacing(1, 2),
      '&:hover': {
        textDecoration: 'none',
        color: '#777',
      },
      [theme.breakpoints.down(1150)]: {
        display: 'none',
      },
    },
    testingLink: {
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: 18,
      color: '#A19DAF',
      justifySelf: 'center',
      textTransform: 'capitalize',
      margin: theme.spacing(1, 2),
      '&:hover': {
        textDecoration: 'none',
        color: '#777',
      },
      [theme.breakpoints.down(1150)]: {
        display: 'none',
      },
    },
    link: {
      fontWeight: 500,
      fontStyle: 'italic',
      fontSize: 18,
      color: '#A19DAF',
      justifySelf: 'center',
      textTransform: 'capitalize',
      margin: theme.spacing(1, 3),
      '&:hover': {
        textDecoration: 'none',
        color: '#777',
      },
      [theme.breakpoints.down(1150)]: {
        display: 'none',
      },
    },
    dropMenuLink: {
      fontWeight: 500,
      fontSize: 18,
      color: 'rgba(0, 0, 0, 0.68)',
      justifySelf: 'center',
      textTransform: 'capitalize',
      margin: theme.spacing(1, 3),
      '&:hover': {
        textDecoration: 'none',
        color: '#777',
      },
      [theme.breakpoints.down(1150)]: {
        display: 'none',
      },
    },
    dropMenuHeaders: {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: 16,
      color: '#5e5e5e',
      justifySelf: 'center',
      textTransform: 'capitalize',
      margin: theme.spacing(1, 3),
      '&:hover': {
        textDecoration: 'none',
        color: '#5e5e5e',
      },
      [theme.breakpoints.down(1150)]: {
        display: 'none',
      },
    },
    headerButton: {
      marginLeft: 15,
    },
    isDisabled: {
      cursor: 'default',
      textDecoration: 'none',
      position: 'relative',
      marginRight: '-25px',
      left: '-30px',
      top: '-15px',
      color: '#de2031',
      fontWeight: '600',
    },
    servicesDropDownIcon: {
      paddingBottom: '1px',
    },
  }));

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleTopServiceClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTopServiceClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Alert className={classes.mainAlert} severity="error" variant="filled">
          <strong>Important Announcement:</strong>
          <br />
          <br />
          Due the unexpected passing of an essential team member, we regret to inform you that we
          {' '}
          are no longer accepting any new orders at this time. We will make every effort to fulfill
          {' '}
          existing orders but know that our customer service responses will be delayed. Thank you
          {' '}
          for your understanding during this difficult time.
          <br />
          <br />
        </Alert>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolBarWrapper}>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              <a href="/">
                <img
                  className={classes.logo}
                  src={`${process.env.PUBLIC_URL}/logo-wide.webp`}
                  alt="logo"
                />
              </a>
            </Typography>
            <Button
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={classes.menuIcon}
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <Typography className={classes.menuIconButton} color="primary">
                Services
                {' '}
                <ChevronDownIcon className={classes.menuServicesIcon} />
              </Typography>
            </Button>
            <Drawer
              className={classes.dockerAppearance}
              variant="persistent"
              anchor="right"
              onClose={handleDrawerClose}
              open={open}
            >
              <div>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button component={Link} to="/nutrients" selected={window.location.pathname === '/nutrients'} key="Nutrient Analysis">
                  <ListItemText
                    key="Nutrient Analysis"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Nutrients Link Clicked',
                        event_label: 'Header - Nutrients Link Clicked',
                        value: 'Header - Nutrients Link Clicked',
                      });
                      if (window.location.pathname === '/nutrients') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Nutrient Analysis
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} selected={window.location.pathname === '/texture'} to="/texture" key="Texture">
                  <ListItemText
                    href="/texture"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Texture Link Clicked',
                        event_label: 'Header - Texture Link Clicked',
                        value: 'Header - Texture Link Clicked',
                      });
                      if (window.location.pathname === '/texture') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Texture Analysis
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/metals" selected={window.location.pathname === '/metals'} key="Heavy Metals">
                  <ListItemText
                    href="/metals"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Metals Link Clicked',
                        event_label: 'Header - Metals Link Clicked',
                        value: 'Header - Metals Link Clicked',
                      });
                      if (window.location.pathname === '/metals') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Lead &#38; Metals
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} selected={window.location.pathname === '/pesticides'} to="/pesticides">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Pesticides Link Clicked',
                        event_label: 'Header - Pesticides Link Clicked',
                        value: 'Header - Pesticides Link Clicked',
                      });
                      if (window.location.pathname === '/pesticides') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Pesticides
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/compost" selected={window.location.pathname === '/compost'} key="Compost">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Compost Link Clicked',
                        event_label: 'Header - Compost Link Clicked',
                        value: 'Header - Compost Link Clicked',
                      });
                      if (window.location.pathname === '/compost') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Compost
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/microorganisms" selected={window.location.pathname === '/microorganisms'} key="Microorganisms">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Microorganisms Link Clicked',
                        event_label: 'Header - Microorganisms Link Clicked',
                        value: 'Header - Microorganisms Link Clicked',
                      });
                      if (window.location.pathname === '/microorganisms') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Biology
                  </ListItemText>
                </ListItem>
              </List>
              <Divider />
              <List>
                <ListItem button component={Link} selected={window.location.pathname === '/packages'} to="/packages">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Packages Link Clicked',
                        event_label: 'Header - Packages Link Clicked',
                        value: 'Header - Packages Link Clicked',
                      });
                      if (window.location.pathname === '/packages') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Package Deals
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} selected={window.location.pathname === '/blog'} to="/blog">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Blog Link Clicked',
                        event_label: 'Header - Blog Link Clicked',
                        value: 'Header - Blog Link Clicked',
                      });
                      if (window.location.pathname === '/blog') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Blog
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} selected={window.location.pathname === '/partner'} to="/partner">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Partner Link Clicked',
                        event_label: 'Header - Partner Link Clicked',
                        value: 'Header - Partner Link Clicked',
                      });
                      if (window.location.pathname === '/partner') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Partner
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} selected={window.location.pathname === '/app'} to="/app">
                  <ListItemText
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - ViewMyResults Clicked',
                        event_label: 'Header - ViewMyResults Clicked',
                        value: 'Header - ViewMyResults Clicked',
                      });
                      if (window.location.pathname === '/app') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Login/Register
                  </ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/" selected={window.location.pathname === '/'} key="Home">
                  <ListItemText
                    key="Home"
                    onClick={() => {
                      gtag('event', 'click', {
                        event_category: 'Header - Home Link Clicked',
                        event_label: 'Header - Home Link Clicked',
                        value: 'Header - Home Link Clicked',
                      });
                      if (window.location.pathname === '/') {
                        handleDrawerClose();
                      }
                    }}
                  >
                    Home
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </div>
          <nav className={classes.navLinks}>
            <Button aria-controls="services-menu" aria-haspopup="true" className={classes.link} onClick={handleTopServiceClick}>
              Testing Services
              {' '}
              <DropDownIcon className={classes.servicesDropDownIcon} />
            </Button>
            <LinkTwo
              variant="button"
              href="/"
              className={classes.homeLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Home Link Clicked',
                  event_label: 'Header - Home Link Clicked',
                  value: 'Header - Home Link Clicked',
                });
              }}
            >
              Home
            </LinkTwo>
            <Menu
              id="services-menu"
              anchorEl={anchorEl}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={Boolean(anchorEl)}
              onClose={handleTopServiceClose}
            >
              <span className={classes.dropMenuHeaders}>Basic Testing</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  style={{ color: 'primary' }}
                  href="/nutrients"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Nutrients Link Clicked',
                      event_label: 'Header - Nutrients Link Clicked',
                      value: 'Header - Nutrients Link Clicked',
                    });
                  }}
                >
                  Nutrient Analysis
                </LinkTwo>
              </MenuItem>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/texture"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Texture Link Clicked',
                      event_label: 'Header - Texture Link Clicked',
                      value: 'Header - Texture Link Clicked',
                    });
                  }}
                >
                  Texture Analysis
                </LinkTwo>
              </MenuItem>
              <span className={classes.dropMenuHeaders}>Contaminants</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/metals"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Metals Link Clicked',
                      event_label: 'Header - Metals Link Clicked',
                      value: 'Header - Metals Link Clicked',
                    });
                  }}
                >
                  Lead &#38; Metals
                </LinkTwo>
              </MenuItem>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/pesticides"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Pesticides Link Clicked',
                      event_label: 'Header - Pesticides Link Clicked',
                      value: 'Header - Pesticides Link Clicked',
                    });
                  }}
                >
                  Pesticides
                </LinkTwo>
              </MenuItem>
              <span className={classes.dropMenuHeaders}>Advanced</span>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/compost"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - CompostLink Clicked',
                      event_label: 'Header - CompostLink Clicked',
                      value: 'Header - CompostLink Clicked',
                    });
                  }}
                >
                  Compost
                </LinkTwo>
              </MenuItem>
              <MenuItem onClick={handleTopServiceClose}>
                <LinkTwo
                  variant="button"
                  href="/microorganisms"
                  className={classes.dropMenuLink}
                  onClick={() => {
                    gtag('event', 'click', {
                      event_category: 'Header - Microorganisms Link Clicked',
                      event_label: 'Header - Microorganisms Link Clicked',
                      value: 'Header - Microorganisms Link Clicked',
                    });
                  }}
                >
                  Microbiology
                </LinkTwo>
              </MenuItem>
            </Menu>
            <LinkTwo
              variant="button"
              href="/packages"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Packages Link Clicked',
                  event_label: 'Header - Packages Link Clicked',
                  value: 'Header - Packages Link Clicked',
                });
              }}
            >
              Package Deals
            </LinkTwo>
            <LinkTwo
              variant="button"
              href="/blog"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Blog Link Clicked',
                  event_label: 'Header - Blog Link Clicked',
                  value: 'Header - Blog Link Clicked',
                });
              }}
            >
              Blog
            </LinkTwo>
            <LinkTwo
              variant="button"
              href="https://partner.rxsoil.com"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - Partner Link Clicked',
                  event_label: 'Header - Partner Link Clicked',
                  value: 'Header - Partner Link Clicked',
                });
              }}
            >
              Partner
            </LinkTwo>
            <LinkTwo
              variant="button"
              href="https://app.rxsoil.com"
              className={classes.testingLink}
              onClick={() => {
                gtag('event', 'click', {
                  event_category: 'Header - ViewMyResults Clicked',
                  event_label: 'Header - ViewMyResults Clicked',
                  value: 'Header - ViewMyResults Clicked',
                });
              }}
            >
              Login/Register
            </LinkTwo>
          </nav>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
